<template>
  <Row :is-header="isHeader" :style="tableStyleObject">
    <CellHeader v-if="isHeader"> Société </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="companyInsuranceRequest" has-padding-inline>
      <template v-if="companyInsuranceRequest?.company?.name">
        <Tooltip :content="companyInsuranceRequest.company.name">
          <TextEllipsis> {{ companyInsuranceRequest.company.name }} </TextEllipsis>
        </Tooltip>
      </template>
      <template v-else> - </template>
    </Cell>

    <CellHeader v-if="isHeader"> Statut </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="companyInsuranceRequest" has-padding-inline>
      <Tooltip :content="translateCompanyInsuranceRequestStatus.fr[companyInsuranceRequest.status]">
        <StatusBadge :variant="mapButtonVariantToStatus(companyInsuranceRequest.status)">
          {{
            companyInsuranceRequest.status.startsWith("approved")
              ? translateCompanyInsuranceRequestStatus.fr.approved
              : translateCompanyInsuranceRequestStatus.fr[companyInsuranceRequest.status]
          }}
        </StatusBadge>
      </Tooltip>
    </Cell>

    <CellHeader v-if="isHeader"> Demandé le </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="companyInsuranceRequest">
      {{ dateFormat(companyInsuranceRequest.created_at, { options: { dateStyle: "medium" } }) }}
    </CellText>

    <CellHeader v-if="isHeader"> Consultant </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="companyInsuranceRequest" has-padding-inline>
      <template v-if="companyInsuranceRequest.owner">
        <TeamMemberAvatar :team-member="companyInsuranceRequest.owner" />
        <TextEllipsis>
          {{ getFullName(companyInsuranceRequest.owner) }}
        </TextEllipsis>
      </template>
      <template v-else> - </template>
    </Cell>

    <template v-if="companyInsuranceRequestState.selectedFilter === 'new'">
      <CellHeader v-if="isHeader"> SIREN </CellHeader>
      <CellSkeleton v-else-if="isSkeleton" />
      <Cell v-else-if="companyInsuranceRequest" has-padding-inline>
        {{ companyInsuranceRequest.siren }}
      </Cell>
    </template>

    <template v-if="companyInsuranceRequestState.selectedFilter !== 'refused'">
      <CellHeader v-if="isHeader"> Montant Permanent </CellHeader>
      <CellSkeleton v-else-if="isSkeleton" />
      <Cell
        v-else-if="companyInsuranceRequest"
        has-padding-inline
        :class="{ 'amount-not-approved': !companyInsuranceRequest.amount_approved }"
      >
        {{
          currencyFormat(companyInsuranceRequest.amount_approved || companyInsuranceRequest.amount_asked, {
            options: {
              minimumSignificantDigits: 1,
            },
          })
        }}
      </Cell>

      <CellHeader v-if="isHeader"> Temporaire </CellHeader>
      <CellSkeleton v-else-if="isSkeleton" />
      <Cell v-else-if="companyInsuranceRequest" has-padding-inline>
        <template
          v-if="
            companyInsuranceRequest.amount_approved_temporarily ||
            companyInsuranceRequest.approved_temporarily_start_date ||
            companyInsuranceRequest.approved_temporarily_end_date
          "
        >
          {{
            companyInsuranceRequest.approved_temporarily_start_date
              ? dateFormat(companyInsuranceRequest.approved_temporarily_start_date, {
                  options: { dateStyle: "medium" },
                })
              : "-"
          }}
          ->
          {{
            companyInsuranceRequest.approved_temporarily_end_date
              ? dateFormat(companyInsuranceRequest.approved_temporarily_end_date, {
                  options: { dateStyle: "medium" },
                })
              : "-"
          }}
          :
          {{
            companyInsuranceRequest.amount_approved_temporarily
              ? currencyFormat(companyInsuranceRequest.amount_approved_temporarily, {
                  options: {
                    minimumSignificantDigits: 1,
                  },
                })
              : "-"
          }}
        </template>
        <template v-else> - </template>
      </Cell>
    </template>

    <template v-if="showAction">
      <CellHeader v-if="isHeader"> </CellHeader>
      <Cell v-if="companyInsuranceRequest" center>
        <Select v-if="possibleAction.length" v-model="action">
          <template #trigger>
            <Button variant="outline" size="icon">
              <ButtonIcon :icon="mapIcon.more_vertical" />
            </Button>
          </template>
          <SelectItem v-for="option in possibleAction" :key="option" :value="option">
            {{ translateCompanyInsuranceRequestOption.fr[option] }}
          </SelectItem>
        </Select>
      </Cell>
    </template>
  </Row>
</template>

<script setup lang="ts">
import type { CompanyInsuranceRequest } from "@asap/shared/src/utils/supabase.types";
import { useCompanyInsuranceRequest } from "~/composables/useCompanyInsuranceRequest";
import {
  translateCompanyInsuranceRequestStatus,
  translateCompanyInsuranceRequestOption,
} from "@asap/shared/src/utils/translate";
import { useToast } from "~/composables/useToast";
import { getFullName } from "@asap/shared/src/utils/people";
import { dateFormat } from "@asap/shared/src/utils/date";
import { mapButtonVariantToStatus } from "./mapButtonVariantToStatus";
import type { CompanyInsuranceRequestPossibleAction } from "@asap/shared/src/types/company-insurance-request";

const props = defineProps<{
  isHeader?: boolean;
  isSkeleton?: boolean;
  companyInsuranceRequest?: CompanyInsuranceRequest;
}>();
const emit = defineEmits(["onChange"]);
const action = ref<CompanyInsuranceRequestPossibleAction>();
const id = props.companyInsuranceRequest?.id;

const { companyInsuranceRequestState, update } = useCompanyInsuranceRequest();
const { addToastError, addToastSuccess } = useToast();
const { isAccounting } = useUserMe();

const showAction = computed(() => {
  return ["all", "new", "sent"].includes(companyInsuranceRequestState.selectedFilter);
});

const tableStyleObject = computed(() => {
  const result = { gridTemplateColumns: `repeat(4, 1fr)` };

  if (companyInsuranceRequestState.selectedFilter === "new") result.gridTemplateColumns = `repeat(7, 1fr)`;
  else if (companyInsuranceRequestState.selectedFilter !== "refused") result.gridTemplateColumns = `repeat(6, 1fr)`;

  if (showAction.value) result.gridTemplateColumns += " 68px";

  return result;
});

const possibleAction = computed<CompanyInsuranceRequestPossibleAction[]>(() => {
  if (!isAccounting.value) return [];

  switch (props.companyInsuranceRequest?.status) {
    case "new":
      return ["send"];
    case "sent":
      return ["approve", "refuse"];
    default:
      return [];
  }
});

watch(action, async (newValue) => {
  if (
    newValue === "send" &&
    !window.confirm("Voulez-vous vraiment marquer cette demande comme envoyée à l'assurance ?")
  ) {
    action.value = undefined;
    return;
  }
  if (id && newValue) {
    companyInsuranceRequestState.selectedId = id;
  }

  switch (newValue) {
    case "send":
      try {
        if (!companyInsuranceRequestState.selectedId) throw new Error("L'ID de la demande n'est pas défini");
        await update(companyInsuranceRequestState.selectedId, {
          status: "sent",
        });
        addToastSuccess({ description: "La demande a été marquée comme envoyée" });
        emit("onChange");
      } catch (error: any) {
        addToastError({ title: "Il y a eu une erreur lors de la mise à jour du statut" }, error);
      }
      break;
    case "approve":
      companyInsuranceRequestState.showApprovedModal = true;
      break;
    case "refuse":
      companyInsuranceRequestState.showRefusedModal = true;
      break;
  }
  action.value = undefined;
});
</script>

<style scoped>
.amount-not-approved {
  color: var(--gray-4);
}
</style>
