import type { Database } from "@asap/shared/src/types/supabase.gen";
import { companyInsuranceRequestColumns } from "@asap/shared/src/utils/supabase.columns.ts";
import type { CompanyInsuranceRequestStatus } from "@asap/shared/src/utils/supabase.enum";
import type { TeamMember } from "@asap/shared/src/utils/supabase.types.ts";

export type CompanyInsuranceRequestPossibleFilter = CompanyInsuranceRequestStatus | "all";
export const companyInsuranceRequestPossibleFilter: readonly CompanyInsuranceRequestPossibleFilter[] = [
  "all",
  "new",
  "sent",
  "approved",
  "refused",
];

type CompanyInsuranceRequestState = {
  selectedId: string | undefined;
  selectedFilter: CompanyInsuranceRequestPossibleFilter;
  showApprovedModal?: boolean;
  showRefusedModal?: boolean;
  showCreateModal?: boolean;
  showSheet?: boolean;
};

export const useCompanyInsuranceRequest = createSharedComposable(() => {
  const supabase = useSupabase();

  const companyInsuranceRequestState = useUrlSearchParams<CompanyInsuranceRequestState>("history", {
    removeFalsyValues: true,
    initialValue: {
      selectedId: undefined,
      selectedFilter: "all",
      showApprovedModal: false,
      showCreateModal: false,
      showRefusedModal: false,
      showSheet: false,
    },
  });

  const selectSingle = async function (id: string) {
    const { data, error } = await supabase
      .from("company_insurance_request")
      .select(companyInsuranceRequestColumns)
      .eq("id", id)
      .single();
    if (error) throw new Error(error.message);
    return { data };
  };

  const selectMultipleForTable = async function (filters: {
    statuses?: CompanyInsuranceRequestPossibleFilter[];
    teamMembers?: TeamMember[];
    siren?: string;
  }) {
    const { statuses, siren, teamMembers } = filters;

    const query = supabase
      .from("company_insurance_request")
      .select(companyInsuranceRequestColumns, { count: "exact" })
      .order("status", { ascending: true })
      .order("created_at", { ascending: false });

    if (statuses && statuses.length && !statuses.includes("all")) {
      if (statuses.length > 0) query.in("status", statuses);
      else query.eq("status", statuses[0]);
    }

    if (teamMembers && teamMembers.length > 0) {
      const teamMemberIds = teamMembers.map((teamMember) => teamMember.id);
      query.in("owner_id", teamMemberIds);
    }

    if (siren) query.eq("siren", siren);

    const { data, count, error } = await query;
    if (error) throw new Error(error.message);
    return { data, count };
  };

  const update = async function (
    id: string,
    values: Database["public"]["Tables"]["company_insurance_request"]["Update"]
  ) {
    const { error } = await supabase
      .from("company_insurance_request")
      .update({
        ...values,
      })
      .eq("id", id)
      .select();
    if (error) throw new Error(error.message);
  };

  const insert = async (values: Database["public"]["Tables"]["company_insurance_request"]["Insert"]) => {
    const { data, error } = await supabase
      .from("company_insurance_request")
      .insert({
        ...values,
      })
      .select("id")
      .single();

    if (error) throw new Error(error.message);
    return { data };
  };

  return {
    companyInsuranceRequestState,
    selectMultipleForTable,
    selectSingle,
    insert,
    update,
  };
});
