<template>
  <template v-if="companyInsuranceRequests.length">
    <CompanyInsuranceRequestRow is-header />
    <VirtualTable :items="companyInsuranceRequests" :item-height="58" :get-key="(item) => item.id">
      <template #item="{ data: companyInsuranceRequest }">
        <CompanyInsuranceRequestRow
          :company-insurance-request="companyInsuranceRequest"
          @click="
            companyInsuranceRequestState.selectedId = companyInsuranceRequest.id;
            companyInsuranceRequestState.showSheet = true;
          "
          @on-change="$emit('onChange')"
        />
      </template>
    </VirtualTable>
  </template>

  <EmptyState v-else>
    <EmptyStateTitle>Aucune demande...</EmptyStateTitle>
    <EmptyStateImage :src="importAsset('images/notioly/graduating.png')" />

    <Button v-if="isConsultant" @click="companyInsuranceRequestState.showCreateModal = true">
      <ButtonIcon :icon="mapIcon.create" />
      Créer une demande
    </Button>
  </EmptyState>

  <LazyCompanyInsuranceRequestSheet
    v-if="companyInsuranceRequestState.showSheet && companyInsuranceRequestState.selectedId"
    :company-insurance-request-id="companyInsuranceRequestState.selectedId"
    @on-close="companyInsuranceRequestState.showSheet = undefined"
  />
</template>

<script setup lang="ts">
import type { CompanyInsuranceRequest } from "@asap/shared/src/utils/supabase.types";
import { useCompanyInsuranceRequest } from "~/composables/useCompanyInsuranceRequest";

type Props = {
  companyInsuranceRequests: CompanyInsuranceRequest[];
};
const props = defineProps<Props>();
defineEmits<{ onChange: [] }>();

const { companyInsuranceRequestState } = useCompanyInsuranceRequest();
const { companyInsuranceRequests } = useVModels(props);
const { isConsultant } = useUserMe();
</script>
