<template>
  <PageContainer>
    <PageHeader title="Garanties sociétés" :icon="mapIcon.company_insurance_request">
      <HeaderActions v-if="isConsultant">
        <Button
          :variant="companyInsuranceRequests.length ? 'primary' : 'outline'"
          :size="companyInsuranceRequests.length ? undefined : 'icon'"
          @click="companyInsuranceRequestState.showCreateModal = true"
        >
          <ButtonIcon :icon="mapIcon.create" />
          <template v-if="companyInsuranceRequests.length">Créer une demande</template>
        </Button>
      </HeaderActions>
    </PageHeader>

    <FilterRow>
      <FilterItems>
        <FilterSearch v-model="search" placeholder="Rechercher une garantie" />
        <ToggleBar>
          <ToggleBarButton
            v-for="status in companyInsuranceRequestPossibleFilter"
            :key="status"
            size="small"
            :variant="companyInsuranceRequestState.selectedFilter === status ? 'outline' : 'ghost'"
            @click="companyInsuranceRequestState.selectedFilter = status"
          >
            {{ status === "all" ? "Tous" : translateCompanyInsuranceRequestStatus.fr[status] }}
          </ToggleBarButton>
        </ToggleBar>
        <TeamMemberFilterButton v-model="teamMembers" :can-switch-workspace-type="canSwitchWorkspace" />
      </FilterItems>
    </FilterRow>

    <PageBody>
      <PageBodyWrapper>
        <PageBodyBox>
          <CompanyInsuranceRequestTable
            :company-insurance-requests="filteredRequests"
            @on-change="fetchCompanyInsuranceRequests"
          />
        </PageBodyBox>

        <LazyCompanyInsuranceRequestCreateModal
          v-if="companyInsuranceRequestState.showCreateModal"
          @on-close="companyInsuranceRequestState.showCreateModal = false"
          @on-create="fetchCompanyInsuranceRequests"
        />

        <LazyCompanyInsuranceRequestApproveModal
          v-if="companyInsuranceRequestState.showApprovedModal"
          @on-close="companyInsuranceRequestState.showApprovedModal = false"
          @on-save="
            async () => {
              companyInsuranceRequestState.showApprovedModal = false;
              await fetchCompanyInsuranceRequests();
            }
          "
        />

        <LazyCompanyInsuranceRequestRefusedModal
          v-if="companyInsuranceRequestState.showRefusedModal"
          @on-close="companyInsuranceRequestState.showRefusedModal = false"
          @on-save="
            async () => {
              companyInsuranceRequestState.showRefusedModal = false;
              await fetchCompanyInsuranceRequests();
            }
          "
        />
      </PageBodyWrapper>
    </PageBody>
  </PageContainer>
</template>

<script setup lang="ts">
import type { CompanyInsuranceRequest, TeamMember } from "@asap/shared/src/utils/supabase.types";
import { getFullName } from "@asap/shared/src/utils/people";
import { translateCompanyInsuranceRequestStatus } from "@asap/shared/src/utils/translate";
import {
  useCompanyInsuranceRequest,
  companyInsuranceRequestPossibleFilter,
} from "~/composables/useCompanyInsuranceRequest";
import type { CompanyInsuranceRequestPossibleFilter } from "~/composables/useCompanyInsuranceRequest";
import { useToast } from "~/composables/useToast";
import { useWorkspaceMe } from "~/composables/useWorkspace";
import { usePosthog } from "~/composables/usePosthog";

const posthog = usePosthog();
const companyInsuranceRequests = ref<CompanyInsuranceRequest[]>([]);
const search = ref("");
const teamMembers = ref<TeamMember[]>([]);

const { addToastError } = useToast();
const { selectMultipleForTable, companyInsuranceRequestState } = useCompanyInsuranceRequest();
const { isConsultant } = useUserMe();
const { canSwitchWorkspace } = useWorkspaceMe();

const fetchCompanyInsuranceRequests = async () => {
  try {
    const statuses: CompanyInsuranceRequestPossibleFilter[] =
      companyInsuranceRequestState.selectedFilter === "approved"
        ? ["approved", "approved_but_maximum_reached", "approved_but_only_contract_minimum", "approved_temporarily"]
        : companyInsuranceRequestState.selectedFilter === "refused"
          ? ["refused", "terminated"]
          : [companyInsuranceRequestState.selectedFilter];

    const { data } = await selectMultipleForTable({ statuses, teamMembers: teamMembers.value });
    if (data) companyInsuranceRequests.value = data;
  } catch (error) {
    addToastError(
      {
        title: "Il y a eu une erreur lors du chargement des demandes de garanties",
      },
      error
    );
  }
};

const { results } = useFuse(search, companyInsuranceRequests, {
  fuseOptions: {
    keys: [
      {
        name: "company",
        getFn: (companyInsuranceRequest) => companyInsuranceRequest.company?.name ?? "",
      },
      {
        name: "siren",
        getFn: (companyInsuranceRequest) => companyInsuranceRequest.siren,
      },
      {
        name: "status",
        getFn: (companyInsuranceRequest) =>
          translateCompanyInsuranceRequestStatus.fr[companyInsuranceRequest.status].toLocaleLowerCase(),
      },
      {
        name: "owner",
        getFn: (companyInsuranceRequest) =>
          companyInsuranceRequest.owner ? getFullName(companyInsuranceRequest.owner).toLocaleLowerCase() : "",
      },
    ],
    threshold: 0.2,
  },
  matchAllWhenSearchEmpty: true,
});

const filteredRequests = computed(() => results.value.map((result) => result.item));

watchEffect(fetchCompanyInsuranceRequests);

onMounted(() => {
  posthog?.trackEvent("company_insurance_request_viewed");
});
</script>
